import BUSD_ABI from "../Contracts/BUSD_ABI.json";
import contractABI from "../Contracts/contract_ABI.json";
import tokenABI from "../Contracts/Token_ABI.json";

// const arr  = {contract:"0x7928986fe9728c35cD49a0240281f03351Ce5672",contractABI:contractABI,BUSD:"0xe37b70Ef457899F0afdFB71CEd2671CFA84ef770",BUSD_ABI:BUSD_ABI}

const arr = {
  contract: "0x01a160D13A6a042D5d6725f89abdB2753A8981BF",
  contractABI: contractABI,
  BUSD: "0x325a4deFFd64C92CF627Dd72d118f1b8361c5691",
  BUSD_ABI: BUSD_ABI,
  token: "0x7263f0797012aF8C6a0Aa9C79Ed86B8354F4E295",
  tokenABI: tokenABI,
};

////////////////testnet//////////////////
// const arr = {
//   contract: "0x98F7A9bB657dE1a6E7BaD6655483850c74A878e1",
//   contractABI: contractABI,
//   BUSD: "0x325a4deFFd64C92CF627Dd72d118f1b8361c5691",
//   BUSD_ABI: BUSD_ABI,
//   token: "0xa30C22b19fCd4A7209d9E55e15D890a350c6Ae4F",
//   tokenABI: tokenABI,
// };

export const ContractDetails = arr;

// Action creators are generated for each case reducer function

export default ContractDetails;
