import React from "react";
import { PieChart } from "react-minimal-pie-chart";
import "./MyChart.css";
import { Data } from "../../Common/Data";
import GetFloatValue from "../../Common/GetFloatValue";
const MyChart = (props) => {
  Data.isDebug && console.log("data", props);
  const root = document.documentElement;
  const rootStyles = getComputedStyle(root);
  const rootColor = rootStyles.getPropertyValue("--colorPrimary");
  const chartMinedValue = props?.stake - props?.mined;

  const data = [
    {
      title: "Mined",
      value: parseFloat(props?.mined ?? 0.00000000001),
      color: rootColor,
    },
    {
      title: "Staking",
      value: parseFloat(chartMinedValue ?? 0.00000000001),
      color: "grey",
    },
  ];

  return (
    <div className="capping">
      <div className="cappingGraphDiv">
        <div style={{ height: "120px" }}>
          <PieChart
            animate={true}
            animationDuration={2000}
            data={data}
            lineWidth={30}
            paddingAngle={2}
            label={({ dataEntry }) => `${Math.round(dataEntry.percentage)}%`}
            labelStyle={{
              fill: "#fff",
              fontSize: "5px",
              fontFamily: "sans-serif",
              fontSize: "8px",
              fontWeight: "bold",
              borderRadius: "50%",
              background: "red !important",
            }}
          />
        </div>
        <div className="cappingAbout ">
          <div>
            <span style={{ background: "grey" }}></span>
            <p>Staking</p>
          </div>
          <div>
            <span style={{ background: rootColor }}></span>
            <p>Mined</p>
          </div>
        </div>
      </div>

      <div className="cappingDetails cappingDetailsDashboard">
        <div>
          <div>
            <h1>
              {GetFloatValue(props.stake - props.mined)} {Data?.coinName}
            </h1>
            <h3>
              $ {GetFloatValue((props.stake - props.mined) * props.liveRate, 4)}
            </h3>
            <p>Staking</p>
          </div>
          <div>
            <h1>
              {props.claimed} {Data?.coinName}
            </h1>
            <h3>$ {GetFloatValue(props.claimed * props.liveRate, 4)}</h3>
            <p>Claimed</p>
          </div>
        </div>
        <div>
          <div>
            <h1>
              {GetFloatValue(props.mined - props.claimed)} {Data?.coinName}
            </h1>
            <h3>
              ${" "}
              {GetFloatValue((props.mined - props.claimed) * props.liveRate, 4)}
            </h3>
            <p>Mined</p>
          </div>
          <div>
            <h1>
              {parseFloat(props.stake / 720).toFixed(4)} {Data?.coinName}
            </h1>
            <h3>$ {GetFloatValue((props.stake * props.liveRate) / 720, 4)}</h3>
            <p>Daily Mining</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyChart;
